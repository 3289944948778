<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <Header />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 margin-top-icon-sm">
            <MediaIcon />
          </div>
          <div class="px-xl-2 mx-auto col-12 text-margin-top-sm">
            <b-card-title class="mb-1">
              Select an option
            </b-card-title>

            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="sentOtp"
            >
              <b-form-group
                label="Select an option"
                label-for="forgot-password-userName"
              >
                <b-form-radio
                  v-model="notifyType"
                  class="form-control-merge mb-1"
                  name="some-radios"
                  value="email"
                >
                  {{ selectIdentity.email }}
                </b-form-radio>

                <b-form-radio
                  v-model="notifyType"
                  name="some-radios"
                  value="mobile"
                >
                  {{ selectIdentity.phone }}
                </b-form-radio>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isDisabled"
              >
                Continue
              </b-button>

            </b-form>

          </div>
        </div>
      </b-col>
      <!-- /Forgot password-->

      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BInputGroup, BRow,
} from 'bootstrap-vue'
import MediaIcon from '@core/components/landding-page/MediaIcon.vue'
import Footer from '@core/components/landding-page/Footer.vue'
import CoverImage from '@core/components/landding-page/CoverImage.vue'
import Header from '@core/components/landding-page/Header.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RecoverIdentity',
  components: {
    BFormInput,
    BInputGroup,
    BCol,
    BFormGroup,
    Header,
    BForm,
    BRow,
    BButton,
    CoverImage,
    Footer,
    BFormRadio,
    MediaIcon,
    BCardTitle,
  },
  data() {
    return {
      isDisabled: true,
    }
  },

  computed: {
    ...mapState('forgot-password', ['selectIdentity']),
    notifyType: {
      get() {
        return this.$store.state['forgot-password'].notifyType
      },
      set(value) {
        this.$store.commit('forgot-password/setNotifyType', value)
      },
    },
  },

  watch: {
    notifyType(val) {
      if (val === '') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
  },

  methods: {
    ...mapActions('forgot-password', ['sentOtp']),
  },
}
</script>

<style scoped lang="scss">

</style>
